import { store } from "@/store/store";
import { categoria_invetario } from "@/shared/dtos/categoria_invetario";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "categoria_invetarioModule",
  store,
  dynamic: true,
})
class categoria_invetarioModule extends VuexModule {
  public categoria_invetarios: categoria_invetario[] = [];
  public categoria_invetario: categoria_invetario = new categoria_invetario();

  @Action({ commit: "onGetcategoria_invetarios" })
  public async getcategoria_invetarios() {
    return await ssmHttpService.get(API.categoria_invetario);
  }

  @Action({ commit: "onGetcategoria_invetario" })
  public async getcategoria_invetario(id: any) {
    return await ssmHttpService.get(API.categoria_invetario + "/" + id);
  }

  @Action({ commit: "onGetcategoria_invetarios" })
  public async getcategoria_invetarios_tree_view() {
    return await ssmHttpService.get(API.categoria_invetario + "/treeview");
  }

  @Action
  public async guardarcategoria_invetario(
    categoria_invetario: categoria_invetario
  ) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    await ssmHttpService.post(
      API.categoria_invetario,
      categoria_invetario.toJson()
    );
    this.getcategoria_invetarios();
  }

  @Action
  public async modificarcategoria_invetario(
    categoria_invetario: categoria_invetario
  ) {
    await ssmHttpService.put(API.categoria_invetario, categoria_invetario);
    this.getcategoria_invetarios();
  }

  @Action
  public async eliminarcategoria_invetario(
    categoria_invetario: categoria_invetario
  ) {
    return await ssmHttpService.delete(
      API.categoria_invetario + "/" + categoria_invetario.id,
      null,
      false
    );
  }

  @Mutation
  public onGetcategoria_invetarios(res: categoria_invetario[]) {
    this.categoria_invetarios = res
      ? res.map((x) => new categoria_invetario(x))
      : [];
  }

  @Mutation
  public onGetcategoria_invetario(res: categoria_invetario) {
    this.categoria_invetario = new categoria_invetario(res);
  }
}
export default getModule(categoria_invetarioModule);
