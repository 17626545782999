import { store } from "@/store/store";
import { invetario } from "@/shared/dtos/invetario";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { document_fileDto } from "@/shared/dtos/document_fileDto";
import { UtilsFiles } from "@/utils/utils-files";

@Module({
  namespaced: true,
  name: "invetarioModule",
  store,
  dynamic: true,
})
class invetarioModule extends VuexModule {
  public invetarios: invetario[] = [];
  public invetario: invetario = new invetario();
  public files: document_fileDto[] = [];


  @Action({ commit: "onGetinvetarios" })
  public async getinvetarios() {
    return await ssmHttpService.get(API.invetario);
  }

  @Action({ commit: "onGetinvetario" })
  public async getinvetario(id: any) {
    return await ssmHttpService.get(API.invetario + "/" + id);
  }

  @Action({ commit: "onGetproyectofiles" })
  public async getfiles(id: any) {
    return await ssmHttpService.get(API.invetario + "/documents/" + id);
  }

  @Action({ commit: 'onGetdescargapdf' })
  public async descargarconsultaPDF() {
      return await ssmHttpService.get(API.invetario + '/pdf_inventarios/', null, false, true);
  }

  @Action
  public async guardarinvetario(invetario: invetario) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(API.invetario, invetario.toJson());
  }

  @Action
  public async modificarinvetario(invetario: invetario) {
    return await ssmHttpService.put(
      API.invetario + "/" + invetario.id,
      invetario
    );
  }

  @Action
  public async eliminarinvetario(invetario: invetario) {
    return await ssmHttpService.delete(
      API.invetario + "/" + invetario.id,
      null,
      false
    );
  }
  
  @Action({ commit: "onGetproyectofiles" })
  public async eliminar_documento({ object, doc }: any) {
    return await ssmHttpService.put(
      API.invetario + "/documents/" + object.id,
      doc,
      false
    );
  }

  @Mutation
  public onGetproyectofiles(res: document_fileDto[]) {
    this.files = res ? res.map((x) => new document_fileDto(x)) : [];
  }

  @Mutation
  public onGetinvetarios(res: invetario[]) {
    this.invetarios = res ? res.map((x) => new invetario(x)) : [];
  }

  @Mutation
  public onGetinvetario(res: invetario) {
    this.invetario = new invetario(res);
  }

  @Mutation
  public async onGetdescargapdf(response: any) {
      UtilsFiles.CreateURLFromBase64AndOpen(response.data, 'application/pdf');
  }

  
}
export default getModule(invetarioModule);
