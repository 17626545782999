














































































































































import { Component, Vue } from "vue-property-decorator";
import DxTreeView from "devextreme-vue/tree-view";
import { categoria_invetario } from "@/shared/dtos/categoria_invetario";
import categoria_invetarioModule from "@/store/modules/categoria_invetario-module";
import { RouterNames } from "@/router/routernames";
import { UtilsString } from "@/utils/utils-string";
import invetarioModule from "@/store/modules/invetario-module";
import { invetario } from "@/shared/dtos/invetario";
import { MessageService } from "@/shared/services/message-service";
@Component({
  components: {
    DxTreeView,
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
  },
})
export default class categoria_invetario_view extends Vue {
  public currentItem: categoria_invetario = new categoria_invetario();
  public NewItem: categoria_invetario = new categoria_invetario();
  public visible: boolean = false;

  created() {
    categoria_invetarioModule.getcategoria_invetarios_tree_view();
  }

  public get data_source() {
    return categoria_invetarioModule.categoria_invetarios;
  }

  public selectItem(e: any) {
    this.currentItem = e.itemData;
  }

  open_form(id: string, id_categoria: string) {
    if (UtilsString.IsNullOrWhiteSpace(id_categoria)) {
      this.$router.push({
        name: RouterNames.inventario_formulario,
        params: { id: id },
      });
    } else {
      this.$router.push({
        name: RouterNames.inventario_formulario,
        params: { id: id },
        query: { id_categoria: id_categoria },
      });
    }
  }

  tiene_hijos(e: categoria_invetario) {
    return e.items.length === 0;
  }

  delete_categoria(e: categoria_invetario) {
    MessageService.confirm(
      "¿Seguro que quiere eliminar la categoría " + e.nombre + "?",
      "Eliminar",
      (x: boolean) => {
        if (x) {
          categoria_invetarioModule
            .eliminarcategoria_invetario(e)
            .then((x) =>
              categoria_invetarioModule.getcategoria_invetarios_tree_view()
            );
        }
      }
    );
  }
  delete_inventario(id: number) {
    MessageService.confirm(
      "¿Seguro que quiere eliminar este inventario?",
      "Eliminar",
      (x: boolean) => {
        if (x) {
          invetarioModule
            .eliminarinvetario(new invetario({ id: id }))
            .then((x) =>
              categoria_invetarioModule.getcategoria_invetarios_tree_view()
            );
        }
      }
    );
  }

  public close() {
    this.visible = false;
    this.NewItem = new categoria_invetario();
  }

  public open_print() {
    invetarioModule.descargarconsultaPDF();
  }

  public aceptar() {
    if (this.currentItem.id !== undefined && this.currentItem.id !== null) {
      this.NewItem.id_padre = this.currentItem.id;
    } else {
      this.currentItem.id = 0;
    }

    categoria_invetarioModule
      .guardarcategoria_invetario(this.NewItem)
      .then(() => {
        this.close();
        categoria_invetarioModule.getcategoria_invetarios_tree_view();
      });
  }
}
